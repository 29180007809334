/* ========================================
  Animations
======================================== */
.a-fade-in {
  opacity: 0;
  animation: fadeIn $t-fade-anim;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.a-fade-in-up {
  opacity: 0;
  animation: fadeInUp $t-fade-anim;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0,30px,0);
  }
  100% {
    opacity: 1;
    transform: none
  }
}

.a-fade-out-up {
  animation: fadeOutUp $t-fade-anim;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: none
  }
  100% {
    opacity: 0;
    transform: translate3d(0,-30px,0);
  }
}