/* ===========================
  HeadingWithText
=========================== */
.HeadingWithText {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  @include scale-property(padding-right, 0, 30px, 20px);
  @include scale-property(padding-left, 0, 30px, 20px);
}

/* ===========================
  Elements
=========================== */
.HeadingWithText__inner {
  width: 660px;
  @include scale-property(margin-top, 37vh, 34vh, 25vh);
}

.HeadingWithText__h1 {
  @include scale-property(margin-bottom, 35px, 25px, 10px);
}

.HeadingWithText__p {
  animation-delay: 50ms;

  a {
    position: relative;
    color: $c-black;
    text-decoration: none;
    transition: color $t-standard;

    &:hover {
      color: $c-grey;
    }
  }

  ul {
    padding-left: 20px;

    a {
      @include link-underline();

      &:hover:before {
        opacity: 0;
      }
    }
  }

  li {
    @media (max-width: $b-small) {
      margin-bottom: 10px;
    }
  }
}
