/*  ==================================
  Breakpoints
=================================== */
$b-small: 720px;
$b-medium: 980px;
$b-xmedium: 1200px;
$b-large: 1400px;
$b-xlarge: 1800px;

/*  ==================================
  Colors
=================================== */
$c-black: #282828;
$c-white: #FFFFFF;
$c-grey: #a0a0a0;

/*  ==================================
  Fonts
=================================== */
$f-heading: 'vogueregular', serif;
$f-body: 'brownregular', sans-serif;

/*  ==================================
  Transitions
=================================== */
$t-default-ms: 250ms;
$t-standard: $t-default-ms linear;
$t-cubic-bez: cubic-bezier(0.77, 0, 0.175, 1);
$t-fade-anim: 2s 0s cubic-bezier(.19,1,.22,1) forwards;

/*  ==================================
  z-index
=================================== */
$z-mobile-nav: 20;
$z-nav: 10;