/* ===========================
  Fonts & Type
=========================== */
@font-face {
  font-family: 'vogueregular';
  src: url('../../fonts/vogue-webfont.woff2') format('woff2'),
       url('../../fonts/vogue-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brownregular';
  src: url('../../fonts/brown-regular-webfont.woff2') format('woff2'),
       url('../../fonts/brown-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: $f-body, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-black;
  font-weight: normal;
  line-height: 1.5;
  @include scale-property(font-size, 20px, 18px, 16px);
}

h1,
h2,
h3,
h4,
p {
  font-weight: normal;
  margin: 0;
}

a:focus {
  outline: none;
}

.f-vogue-72-42 {
  font-family: $f-heading;
  line-height: 1;
  @include scale-property(font-size, 72px, 56px, 42px);
}

.f-vogue-26-18 {
  font-family: $f-heading;
  line-height: 1;
  @include scale-property(font-size, 26px, 20px, 18px);
}
