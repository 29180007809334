/* ===========================
  Navbar
=========================== */
.Navbar {
  position: fixed;
  width: 100%;
  display: flex;
  left: 0;
  justify-content: space-between;
  top: 0;
  background: $c-white;
  z-index: $z-nav;
  @include scale-property(padding-top, 60px, 35px, 20px);
  @include scale-property(padding-right, 40px, 30px, 20px);
  @include scale-property(padding-bottom, 40px, 30px, 20px);
  @include scale-property(padding-left, 40px, 30px, 20px);
}

/* ===========================
  Elements
=========================== */
.Navbar__burger {
  display: none;
  height: 16px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $b-small) {
    display: flex;
  }
}

.Navbar__burger-bar {
  width: 26px;
  height: 2px;
  background-color: $c-black;
}

.Navbar__ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @for $i from 0 through 5 {
    .Navbar__li:nth-child(#{$i}) {
      animation-delay: $i * 50ms;
    }
  }

  @media (max-width: $b-small) {
    display: none;
  }
}

.Navbar__li {
  font-size: 0;
  margin-right: 30px;
}

.Navbar__link {
  position: relative;
  text-decoration: none;
  color: $c-black;
  transition: color $t-standard;
  @include link-underline();

  &:before {
    opacity: 0;
  }

  &.is-active {
    &:before {
      opacity: 1;
    }
  }

  &:hover {
    color: $c-grey;

    &:before {
      background-color: $c-grey;
    }
  }
}

.Navbar__social {
  font-size: 0;
  @include scale-property(height, 20px, 18px, 16px);

  .Navbar__social-link:first-of-type {
    margin-right: 10px;
  }
}

.Navbar__social-link {
  color: $c-black;
  font-size: 0;
  transition: $t-standard;
  @include scale-property(height, 20px, 18px, 16px);

  &:hover {
    color: $c-grey;
  }
}

.Navbar__social-icon {
  @include scale-property(height, 20px, 18px, 16px);
}

.Navbar__close {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* ===========================
  Modifiers
=========================== */
.Navbar--mobile-is-open {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  z-index: $z-mobile-nav;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  .Navbar__burger,
  .Navbar__social {
    display: none;
  }

  .Navbar__ul {
    display: flex;
    width: 175px;
    height: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }

  .Navbar__li {
    margin-bottom: 8px;
  }

  .Navbar__link {
    font-size: 32px;
  }

  .Navbar__close {
    display: block;
  }
}
